import { createApp } from 'vue';
import { createWebHashHistory, createRouter } from 'vue-router';
import App from "./App.vue";
import Routes from './routes.js';
import { createI18n } from 'vue-i18n';
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import axios from 'axios';

import "../static/styles/stylesheet.scss";
import en from '../static/languages/en.json';
import am from '../static/languages/am.json';

const HttpRefreshTokenExpired = 401;
const HttpAccessTokenExpired = 403;

const vuetify = createVuetify({
  components,
  directives,
})

const router = createRouter({
  history: createWebHashHistory(),
  routes: Routes
});

let isRefreshing = false;
let failedQueue = [];

let token = localStorage.getItem('access_token');
if (token) {
  axios.defaults.headers.common['Authorization'] = token;
}

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axios.interceptors.response.use(
  response => response,
  error => {
    const originalRequest = error.config;
    if (error.response && error.response.status === HttpRefreshTokenExpired) { // 401
      window.location.href = '/';
      failedQueue = [];
      return Promise.reject(error);
    } else if (error.response && error.response.status === HttpAccessTokenExpired) { // 403

      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({resolve, reject});
        }).then(token => {
          originalRequest.headers['Authorization'] = token;
          return axios(originalRequest);
        });
      }

      isRefreshing = true;

      return new Promise((resolve, reject) => {
        let refresh_token = localStorage.getItem('refresh_token');
        let data = {"token": refresh_token};
        axios.post('/refresh/', data).then(response => {
          let token = response.data['access_token'];
          localStorage.setItem('access_token', token);
          axios.defaults.headers.common['Authorization'] = token;
          originalRequest.headers['Authorization'] = token;

          processQueue(null, token);
          resolve(axios(originalRequest));
        }).catch(error => {
          processQueue(error, null);
          localStorage.removeItem('access_token');
          localStorage.removeItem('refresh_token');
          localStorage.removeItem('username');
          localStorage.removeItem('avatar');
          window.location.href = '/';
        }).finally(() => {
          isRefreshing = false;
        });
      });

    } else {

      let error_msg = "Error. Please contact with wav.am"
      if (error.response && error.response.data) {
        error_msg = error.response.data;
      } else if (error.message) {
        error_msg = error.message;
      }

      return Promise.reject(error_msg);
    }
  });

const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem('lang') || 'am',
  fallbackLocale: 'en',
  messages: { en, am }
});

const app = createApp(App)
app.config.globalProperties.localize = i18n.global.t;

app.use(i18n);
app.use(vuetify)
app.use(router)
app.mount('#app')
