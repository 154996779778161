<template>
  <div>
    <div v-if="orientation === 'portrait'">
      <router-view />
    </div>
    <div v-else class="screen_blocked">
      <img src="../static/images/Logo.svg" alt=""/>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      orientation: this.getOrientation(),
    };
  },
  watch: {
    '$i18n.locale'(newLocale) {
      document.documentElement.setAttribute('lang', newLocale);
    },
  },
  methods: {
    getOrientation() {
      if ((window.orientation === 90 || window.orientation === -90) && window.innerWidth < 760) {
        alert("Please rotate the screen.")
        return 'landscape';
      }
      return 'portrait';
    },
    orientationChange() {
      this.orientation = this.getOrientation();
    },
  },
  mounted() {
    document.documentElement.setAttribute('lang', this.$i18n.locale);
    window.addEventListener("orientationchange", this.orientationChange);
  },
  beforeUnmount() {
    window.removeEventListener("orientationchange", this.orientationChange);
  },
};
</script>

